import { render, staticRenderFns } from "./GxSum.vue?vue&type=template&id=5e98536e&scoped=true"
import script from "./GxSum.vue?vue&type=script&lang=js"
export * from "./GxSum.vue?vue&type=script&lang=js"
import style0 from "./GxSum.vue?vue&type=style&index=0&id=5e98536e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e98536e",
  null
  
)

export default component.exports