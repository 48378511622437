<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>工序管理</el-breadcrumb-item>
      <el-breadcrumb-item>工序统计</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="10" type="flex" align="middle">
        <el-col :span="18">
          <el-form class="searchDiv" :inline="true" size="mini">

            <el-form-item label="员工ID" label-width="90px">
              <el-input
                  clearable
                  placeholder="搜索员工ID"
                  v-model="searchFieds.submit_auid"
              ></el-input>
            </el-form-item>

            <el-form-item label="工序" label-width="90px">
              <el-input
                  clearable
                  placeholder="搜索工序"
                  v-model="searchFieds.job_name"
              ></el-input>
            </el-form-item>

            <el-form-item label="起止日期">
              <el-date-picker
                  v-model="day_arr"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

            <!--            <el-form-item label="是否使用" label-width="80px">-->
            <!--              <el-select v-model="searchFieds.is_use" clearable placeholder="请选择">-->
            <!--                <el-option-->
            <!--                    v-for="(item2, index2) in ['否', '是']"-->
            <!--                    :key="index2"-->
            <!--                    :label="item2"-->
            <!--                    :value="index2"-->
            <!--                >-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->

          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form :inline="true" class="myFormDiv">
            <el-row :gutter="10" type="flex" align="middle">

              <el-col :span="8">
                <el-form-item>
                  <el-button size="mini" round type="success" @click="onSubmit"
                  >搜索
                  </el-button>
                </el-form-item>
              </el-col>

              <!--              <el-col :span="8">-->
              <!--                <el-form-item>-->
              <!--                  <el-button size="mini" round type="warning" @click="onAdd">新增-->
              <!--                  </el-button>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->

              <el-col :span="8">
                <el-form-item>
                  <el-button size="mini" round type="info" @click="onReset"
                  >重置
                  </el-button>
                </el-form-item>
              </el-col>

            </el-row>


          </el-form>

        </el-col>

      </el-row>
    </el-card>

    <!--用户列表区 -->
    <div class="table">
      <el-table
          ref="myTable"
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
          show-summary
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="showSelection==true" type="selection" width="55"></el-table-column>

        <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
        >
          <template slot-scope="scope">
            <div v-if="item.type == 'array'  || item.type == 'arrayObj'">
              {{ getOptionVal(item.data, scope.row[item.prop], item.type) }}
            </div>
            <div v-else-if="item.type == 'html'" v-html="scope.row[item.prop]">

            </div>
            <!--显示图-->
            <div
                v-else-if="
                  item.type  == 'pic' "
            >
              <el-image
                  v-if="scope.row[scope.column['property']]"
                  :src="$getUrl(scope.row[scope.column['property']])"
                  fit="scale-down"
                  style="height: 100px"
              />
            </div>

            <div class="show-btn" v-else-if=" item.type =='showHtml' && scope.row[scope.column['property']]">
              <el-button type="success" @click="$showHtml(scope.row[scope.column['property']])">查看</el-button>
            </div>

            <div v-else>
              {{ scope.row[scope.column["property"]] }}
            </div>

          </template>
        </el-table-column>

      </el-table>
    </div>

    <!--分页区-->
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>


    <!--编辑窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem[keyID] ? '新增' : '编辑'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <div style="max-height: 60vh;overflow-y: auto;">
        <el-form :inline="false" label-width="150px">
          <el-row>
            <el-col
                class="myElCol"
                :span="11"
                v-for="(item, index) in propList"
                :key="index"
            >
              <el-form-item :label="item.label">
                <el-select
                    v-if="item.type == 'array'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2"
                      :value="index2"
                  >
                  </el-option>
                </el-select>

                <el-select
                    v-else-if="item.type == 'arrayObj'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2.title"
                      :value="item2.val"
                  >
                  </el-option>
                </el-select>


                <el-select
                    v-else-if="item.type == 'list'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2"
                      :value="item2"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                    v-else-if="item.type == 'time'"
                    v-model="editItem[item.prop]"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间"
                >
                </el-date-picker>
                <el-input
                    v-else
                    :type="item.type == 'textarea'
                  || item.type == 'showHtml'
                   || item.type == 'pic'
                    || item.type == 'video'
                  ? 'textarea' : 'text'"
                    :disabled="item.prop == keyID"
                    :rows="2"
                    clearable
                    v-model="editItem[item.prop]"
                >
                </el-input>
                <el-button style="margin-top: 5px;" v-if="item.type == 'showHtml'" type="primary"
                           @click="$copy(editItem[item.prop])">复制代码
                </el-button>

                <MyUploadPlus
                    v-if="
                  item.upload == true"
                    accept="*"
                    fileType="file"
                    :disabled="false"
                    @urlChange="(e) => onUrlChange(item.prop, e,item.json==true)"
                />

              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>


<script>


import {mapState} from "vuex";

export default {
  data() {
    return {
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,

      //----------------------以下为每次的配置--------------

      /**
       */

      //表格字段
      propList: [

        {
          prop: "submit_auid",
          label: "员工账号ID",
          width: 150,
        },
        {
          prop: "wx_nick",
          label: "员工昵称",
          width: 150,
        },
        {
          prop: "job_name",
          label: "职责名称",
          width: 180,
        },
        {
          prop: "n",
          label: "提交次数",
          width: 150,

        },

      ],
      //搜索字段
      searchFieds: {
        submit_auid: '',
        job_name: '',
      },
      //base64编码字段列表
      base64Fields: [],
      //主键ID
      keyID: 'id',
      //调整表格高度
      subHeight: 300,
      //类方法URL--获取列表
      methods_getList: 'GxSum/getList',
      //类方法URL--保存数据
      methods_saveData: 'GxSum/saveData',
      //类方法URL--删除一行数据
      methods_delItem: 'GxSum/delItem',
      //操作列是否固定右边
      fixedRight: false,
      //显示选择框
      showSelection: false,

      // ------------------其它临时数据
      // day_arr: [new Date(), new Date()],
      day_arr:[],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()]);
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          }
          ,
          {
            text: '最近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }


        ]
      },


    };
  },
  computed: {
    ...mapState([]),
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - this.subHeight;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
      };
      //添加搜索词
      for (let key in this.searchFieds) {
        this.$set(obj, key, this.searchFieds[key])
      }
      //加上起止
      if (this.day_arr && this.day_arr.length>=2) {
        this.$set(obj, 'day_start', this.day_arr[0])
        this.$set(obj, 'day_end', this.day_arr[1])
      }
      console.log('day_arr', this.day_arr);
      const res = await this.$post(this.methods_getList, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data;

      this.total = this.tableData.length//res.data.total;
      console.log('this.tableData', this.tableData)

      this.$nextTick(() => {
        this.$refs.myTable.doLayout(); // 刷新表格布局
      });
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.page = 1
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));

      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      //添加时主键为0
      this.$set(obj, this.keyID, 0);

      this.editItem = obj;

      this.showEditDlg = true;
    },
    //点击确定保存信息
    async onSaveEdit() {
      let obj = {
        data: JSON.parse(JSON.stringify(this.editItem)),
      };
      this.$set(obj, this.keyID, this.editItem[this.keyID])

      //处理需编码的字段
      for (let i in this.base64Fields) {
        let field = this.base64Fields[i]
        obj.data[field] = this.base64EnCode(obj.data[field])
      }

      const res = await this.$post(this.methods_saveData, obj);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },

    onUrlChange(fielddName, e, json = false) {
      console.log("获取 getUrlChange", fielddName, e);
      if (json == true) {
        let arr = this.editItem[fielddName] || []
        arr.push(e[0].url);
      } else {
        this.editItem[fielddName] = e[0].url;
      }
    },
    //选择框改变
    handleSelectionChange(val) {
      console.log(val);
      this.selectRow = val;
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该信息
      const confirmResult = await this.$confirm(
          "此操作将永久删除该信息?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let obj = {};
      this.$set(obj, this.keyID, item[this.keyID])

      console.log(obj);
      const res = await this.$post(this.methods_delItem, obj);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //按评论ID删除
    onDelByComID() {
      this.$prompt("请输入评论ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "职位ID格式不正确",
      })
          .then(({value}) => {
            if (parseInt(value) > 0) {
              this.callDelByComID(parseInt(value));
            } else {
              this.$err("评论ID有误");
            }
          })
          .catch(() => {
            this.$info("取消输入");
          });
    },
    async callDelByComID(comid = 0) {
      console.log(comid);
      const res = await this.$post(`ZhaoJobCommentFavorOppose/delCommentFavorOppose`, {
        comid,
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
    //按用户ID删除
    onDelByUserID() {
      this.$prompt("请输入用户ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "用户ID格式不正确",
      })
          .then(({value}) => {
            if (parseInt(value) > 0) {
              this.callDelByUserID(parseInt(value));
            } else {
              this.$err("用户ID有误");
            }
          })
          .catch(() => {
            this.$info("取消输入");
          });
    },
    async callDelByUserID(app_userid = 0) {
      console.log(app_userid);

      const res = await this.$post(`ZhaoJobCommentFavorOppose/delCommentFavorOpposeByUserID`, {
        app_userid,
        comid: this.searchFieds.comid
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.searchFieds.comid = ''
      this.getList(this.page);
    },
    //重置搜索
    onReset() {
      for (let i in this.searchFieds) {
        this.searchFieds[i] = ''
      }
      this.getList();
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    }

  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.show-btn {
  display: flex;
  justify-content: center;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.checkItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 100px;
  }
}


</style>
