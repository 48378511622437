import Vue from 'vue'
import Vuex from 'vuex'

//持久化存储
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const myState = {
  //缓存的标签页组
  editableTabs: [],
  //当前显示的标签--authName
  editableTabsValue: '',
  //当前显示的小标签--标题
  editableTabsTitle: '',
  //当前显示的大菜单-
  editableAuthName: '',

  //登录后信息
  auid: 0,
  //角色
  user_power: '',
  //可用模块列表
  power: '',
  //可用类列表
  classNameList: [],
  //可用类 表格列表
  powerDbList: [],
  //登录令牌
  token: '',
  //左侧菜单数据---当前用户权限下的
  menulistBack: [],

  //当前各菜单记录条数241010新增
  menuListNum:{},

}

const options = {
  state: JSON.parse(JSON.stringify(myState)),
  mutations: {
    //设置我的菜单项
    setMyMenu(state, list) {
      let obj = {}
      //先生成对象
      for (let i in list) {
        let mainAuthName = list[i]['mainAuthName']
        let authName = list[i]['authName']
        let path = list[i]['path']
        let iconValue = list[i]['iconValue']
        //没有图标的，不要
        if(!iconValue || iconValue.length<1) continue
        //主 authName 做为 key
        if (!obj[mainAuthName]){
          obj[mainAuthName] = {}
          obj[mainAuthName]['children']=[]
        }
        obj[mainAuthName]['icon']=iconValue
        //加入对象
        obj[mainAuthName]['children'].push({
          authName,
          path,
        })
      }
      //生成菜单
      let menu = []
      for (let key in obj) {
        menu.push({
          authName: key,
          icon:obj[key]['icon'],
          children: obj[key]['children'],
        },)
      }
      state['menulistBack'] = menu
    },
    //添加一项
    addItem(state, item) {
      state.editableTabs.push(item)
    },
    //删除一项
    delItem(state, name) {
      let index = state.editableTabs.findIndex((item) => {
        return item.name == name;
      });
      if (index > -1) {
        state.editableTabs.splice(index, 1);
        console.log("删除组件", name)
      }

    },
    //清空全部 保留第一个  //是否强制清空，否则保留一个
    clearAll(state, isClear = false) {
      if (state.editableTabs.length < 1) return

      let item = state.editableTabs[0]
      state.editableTabs = []
      if (isClear == false) {
        //保留第一个
        state.editableTabs.push(item)
        state.editableTabsValue = item.name

        //调用方法
        this.commit('changeTabsTitle')
      }
    },

    //设置当前显示的标签内容
    setTabsValue(state, value) {
      console.log("设置当前显示的标签内容:" + value)
      state.editableTabsValue = value
      //调用方法
      this.commit('changeTabsTitle')
    },
    //设置对象数据
    setObj(state, obj) {
      for (let key in obj) {
        state[key] = obj[key]
      }
    },
    //清空用户登录信息
    clearUserInfo() {

    },
    //改变当前显示的标签标题与大菜单名称
    changeTabsTitle(state) {
      // console.log("改变当前显示的标签标题与大菜单名称:" )
      //查出当前显示的标签标题
      let tabsValue = state.editableTabsValue
      let menuList = state.menulistBack
      //当前选中小菜单名称
      let tabsTitle = ''
      //当前选中大菜单名称
      let authName = ''
      for (let i in menuList) {
        for (let j in menuList[i]['children']) {
          if (tabsValue == menuList[i]['children'][j]['path']) {
            tabsTitle = menuList[i]['children'][j]['authName']
            authName = menuList[i]['authName']
            // console.log("tabsTitle=" + tabsTitle)
            // console.log("authName=" + authName)
            break
          }
        }
        if (tabsTitle) break
      }
      //赋值当前显示的小标签--标题
      state.editableTabsTitle = tabsTitle
      //赋值 当前显示的大菜单
      state.editableAuthName = authName
      // console.log("改变后:" ,state.editableTabsTitle,state.editableAuthName)
    }

  },

}

export const store = new Vuex.Store({
  ...options,
  plugins: [
    createPersistedState({
      key: 'rw_admin',
      storage: {
        getItem: key => window.localStorage.getItem(key),
        setItem: (key, value) => window.localStorage.setItem(key, value),
        removeItem: key => window.localStorage.removeItem(key)
      }
    })
  ],
})

// 获取一个空的store
export function getNewStore() {
  let newStore = (new Vuex.Store({
    state: JSON.parse(JSON.stringify(myState)),
  })).state
  return newStore
}