<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>后台用户</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="8">
          <!--搜索与添加-->
          <el-input
              placeholder="请输入搜索内容"
              v-model="searchKey"
              clearable
              width="40"
          >
            <!--实现搜索功能  v-model：实现数据双向绑定，clearable 清空文本框，clear：在点击由 clear属性生成的清空按钮时触发-->
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="success" @click="onSubmit"
          >搜索
          </el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button size="mini" round type="warning" @click="onAdd"
          >新增
          </el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
            :data="tableData"
            :border="true"
            :height="getH()"
            stripe
            :resizable="true"
        >
          <el-table-column
              show-overflow-tooltip
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
          >
            <!--显示图片-->
            <template slot-scope="scope">
              <img
                  v-if="
                  scope.column['property'] == 'user_log' &&
                  scope.row['user_log_val']
                "
                  :src="scope.row['user_log_val']"
                  width="20"
                  height="20"
              />

              <div v-else>
                {{ scope.row[scope.column["property"]] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                    type="text"
                    size="small"
                    @click="editAdminUser(scope.row)"
                >编辑
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="remvAdminUser(scope.row)"
                >
                  删除
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="resetPwd(scope.row)"
                >
                  重置密码
                </el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem.uid ? '新增用户' : '编辑用户'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="item2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-select
                  v-else-if="item.type == 'list'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="item2"
                >
                </el-option>
              </el-select>
              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'uid'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,

      searchKey: "",
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
       `uid` int(10) unsigned NOT NULL AUTO_INCREMENT,
       `user_power` varchar(255) NOT NULL DEFAULT '' COMMENT '账号角色',
       `login_user` varchar(255) NOT NULL DEFAULT '' COMMENT '登录帐号',
       `login_pwd` varchar(255) NOT NULL DEFAULT '' COMMENT '密码',
       `reg_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '注册时间',
       `lastlogin_time` timestamp NULL DEFAULT NULL COMMENT '最后登录时间',
       `lastlogin_info` varchar(255) DEFAULT NULL COMMENT '最后登录客户信息',
       *
       */
      propList: [
        {
          prop: "uid",
          label: "用户ID",
        },

        {
          prop: "user_power",
          label: "账号角色",
          width: 200,
          type: 'list',
          data: [],
        },

        {
          prop: "login_user",
          label: "登录帐号",
          width: 120,
        },
        /*
        {
          prop: "login_pwd",
          label: "密码",
          width: 120,
        },
        */
        {
          prop: "reg_time",
          label: "注册时间",
          width: 300,
          type: 'time'
        },
        /*
       {
          prop: "user_phone",
          label: "手机号",
          width: 200,
        },
        */
        {
          prop: "lastlogin_time",
          label: "最后登录时间",
          width: 200,
          type: 'time'
        },
        {
          prop: "lastlogin_info",
          label: "最后登录客户信息",
          width: 200,
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.onGetJiaoseNameList()
    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 240;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //获取角色列表
    async onGetJiaoseNameList() {
      const res = await this.$post(`AdminUser/getJiaoseNameList`);
      console.log(res);
      if (res.code !== 200) return;

      this.jiaoseNameList = res.data
      this.initArr()

      this.getList();
    },
    initArr() {
      for (let i in this.propList) {
        if (this.propList[i]["prop"] == "user_power") {
          this.propList[i]["data"] = this.jiaoseNameList;
        }
      }
    },
    //获取用户参数列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
      };
      const res = await this.$post(`AdminUser/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      // console.log(this.tableData,res.data.data)
      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    editAdminUser(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let Obj1 = {
        uid: this.editItem.uid,
        data: this.editItem,
      };
      const res = await this.$post(`AdminUser/saveData`, Obj1);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async remvAdminUser(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
          "此操作将永久删除该后台用户信息?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        uid: item.uid,
      };
      console.log(obj2);
      const res = await this.$post(`AdminUser/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    async resetPwd(item) {
      let obj = await this.$prompt("请输入新密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.{6,15}/,
        inputErrorMessage: "密码长度需6-15",
      }).catch(() => {
        this.$info({
          type: "info",
          message: "取消输入",
        });
        return;
      });
      console.log("obj", obj);
      if (!obj || obj.length < 1) {
        return;
      }
      let pwd = obj.value;
      const confirmResult = await this.$confirm(
          "确认密码重置为: " + pwd + " 吗?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);

      if (confirmResult !== "confirm") {
        return;
      }

      //提交
      this.callRetSetPwd(item, pwd);
    },
    //提交密码
    async callRetSetPwd(item = null, pwd = "") {
      console.log("pwd", pwd);
      let obj = {
        uid: item.uid,
        login_user: item.login_user,
        login_pwd: pwd,
      };
      const res = await this.$post(`AdminUser/forget`, obj);
      console.log("提交密码", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
    },
  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
