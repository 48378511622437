<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="16">
          <!--搜索与添加-->
          <el-input
            placeholder="请输入搜索内容"
            v-model="search"
            clearable
            width="40"
          >
            <!--实现搜索功能  v-model：实现数据双向绑定，clearable 清空文本框，clear：在点击由 clear属性生成的清空按钮时触发-->
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </el-card>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>


    <!--编辑窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.jid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="item.prop == 'power'"
                v-model="editItem[item.prop]"
                :collapse-tags="false"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in powerList"
                  :key="index2"
                  :label="item2"
                  :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'jid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,

      search: "",
      //用户数据列表 默认为空
      tableData: [],
      /**
       * 
         `jid` int(11) NOT NULL AUTO_INCREMENT,
        `createTime` timestamp NULL DEFAULT CURRENT_TIMESTAMP COMMENT '创建时间',
        `createMuid` int(11) NOT NULL COMMENT '创建用户ID',
        `jiaoseName` varchar(255) NOT NULL DEFAULT '' COMMENT '角色名称',
        `jiaoseText` varchar(255) NOT NULL DEFAULT '' COMMENT '角色描述',
        `power` text COMMENT '权限',
       * 
       */
      propList: [
        {
          prop: "jid",
          label: "ID",
        },
        {
          prop: "createTime",
          label: "创建时间",
          type: "time",
          width: 180,
        },
        {
          prop: "createMuid",
          label: "创建用户ID",
        },
        {
          prop: "userName",
          label: "账号昵称",
          width: 150,
        },
        {
          prop: "jiaoseName",
          label: "角色名称",
          width: 120,
        },
        {
          prop: "jiaoseText",
          label: "角色描述",
          width: 200,
        },
        {
          prop: "power",
          label: "权限",
          width: 350,
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
      //所有权限列表
      powerList: [],
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getPowerList();

  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 270;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //获取权限列表
    async getPowerList() {
      const res = await this.$post(`AdminJiaose/getPowerList`);
      console.log(res);
      if (res.code !== 200) return;

      this.powerList = res.data;

      this.getList();
    },
    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
      };
      const res = await this.$post(`AdminJiaose/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      //字符串转数组
      console.log('this.editItem["power"]:' + this.editItem["power"]);
      if (this.editItem["power"] && this.editItem["power"].length > 0) {
        this.editItem["power"] = this.editItem["power"].split(",");
      }
      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存信息
    async onSaveEdit() {
      let obj = {
        jid: this.editItem.jid,
        data: this.editItem,
      };
      //数组改为字符串
      if (Array.isArray(obj.data["power"])) {
        obj.data["power"] = obj.data["power"].join(",");
      }

      const res = await this.$post(`AdminJiaose/saveData`, obj);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let obj = {
        jid: item.jid,
      };
      console.log(obj);
      const res = await this.$post(`AdminJiaose/delItem`, obj);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
  },
};
</script>



<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
