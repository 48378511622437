<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="10" type="flex" align="middle">
        <el-col :span="18">
          <el-form class="searchDiv" :inline="true" size="mini">

            <el-form-item label="关键词" label-width="90px">
              <el-input
                  clearable
                  placeholder="搜索关键词"
                  v-model="searchFieds.search"
              ></el-input>
            </el-form-item>

            <!--            <el-form-item label="商品分类" label-width="90px">-->
            <!--              <el-input-->
            <!--                  clearable-->
            <!--                  placeholder="搜索商品分类"-->
            <!--                  v-model="searchFieds.goods_cate"-->
            <!--              ></el-input>-->
            <!--            </el-form-item>-->

            <el-form-item label="商品分类" label-width="80px">
              <el-select v-model="searchFieds.goods_cate" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in cateList"
                    :key="index2"
                    :label="item2"
                    :value="item2"
                >
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="商品编码" label-width="90px">
              <el-input
                  clearable
                  placeholder="搜索商品编码"
                  v-model="searchFieds.goods_number"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否隐藏" label-width="80px">
              <el-select v-model="searchFieds.is_hide" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in ['否', '是']"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!--            <el-form-item label="排序方式" label-width="80px">-->
            <!--              <el-select v-model="searchFieds.orderType" clearable placeholder="请选择">-->
            <!--                <el-option-->
            <!--                    v-for="(item2, index2) in ['ID倒序', '置顶倒序']"-->
            <!--                    :key="index2"-->
            <!--                    :label="item2"-->
            <!--                    :value="index2"-->
            <!--                >-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->

          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form :inline="true" class="myFormDiv">
            <el-row :gutter="10" type="flex" align="middle">

              <el-col :span="8">
                <el-form-item>
                  <el-button size="mini" round type="success" @click="onSubmit"
                  >搜索
                  </el-button>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item>
                  <el-button size="mini" round type="warning" @click="onAdd">新增
                  </el-button>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item>
                  <el-button size="mini" round type="info" @click="onReset"
                  >重置
                  </el-button>
                </el-form-item>
              </el-col>

            </el-row>


          </el-form>

        </el-col>

      </el-row>
    </el-card>

    <!--用户列表区 -->
    <div class="table">
      <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
          @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="showSelection==true" type="selection" width="55"></el-table-column>

        <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
        >
          <template slot-scope="scope">
            <div v-if="item.type == 'array'  || item.type == 'arrayObj'">
              {{ getOptionVal(item.data, scope.row[item.prop], item.type) }}
            </div>
            <div v-else-if="item.type == 'html'" v-html="scope.row[item.prop]">

            </div>
            <!--显示图-->
            <div
                v-else-if="
                  item.type  == 'pic' "
            >
              <el-image
                  v-if="scope.row[scope.column['property']]"
                  :src="$getUrl(scope.row[scope.column['property']])"
                  fit="scale-down"
                  style="height: 100px"
              />
            </div>

            <div class="show-btn" v-else-if="item.type ==='showHtml' && scope.row[scope.column['property']]">
              <el-button type="success" @click="$showHtml(scope.row[scope.column['property']])">查看</el-button>
            </div>

            <div class="show-btn"
                 v-else-if="item.prop ==='goods_number' && !scope.row['goods_number']">

              <el-button type="success" style="margin-right: 10px;"
                         @click="onCreateCode(scope.row['id'])">1、生成编码
              </el-button>
              <span>{{ scope.row[scope.column["property"]] }}</span>
            </div>

            <div class=""
                 v-else-if="item.prop ==='product_job' && scope.row['goods_number'] && scope.row['custom_id']">

              <el-button type="success" style="margin-right: 10px;"
                         @click="onCreateJob(scope.row['id'])">
                4、{{ scope.row['product_job'] ? '重新生成' : '生成工序' }}
              </el-button>
              <span v-html="scope.row[scope.column['property']]"></span>
            </div>

            <div class=""
                 v-else-if="item.prop ==='curr_index'
                 && scope.row['goods_number']
                 && scope.row['goods_store']
                 && scope.row['not_create_product']<1
              ">
              <span v-html="scope.row[scope.column['property']]"></span>
              <el-button type="success" style="margin-right: 10px;"
                         @click="onCreateProduct(scope.row['id'])">5、生成产品工序
              </el-button>

            </div>

            <div class="show-btn"
                 v-else-if="item.prop ==='custom_id' && scope.row['goods_number']">

              <el-button v-if="!scope.row['custom_id']" type="success" style="margin-right: 10px;" size="small"
                         @click="createCustomId(scope.row['id'])">2、创建关联
              </el-button>

              <el-button
                  type="warning"
                  style="margin-right: 10px;"
                  v-if="scope.row['custom_id']"
                  size="small"
                  @click="onEditGoodInfo(scope.row)"
              >3、编辑商品
              </el-button
              >
            </div>


            <div v-else-if="item.type ==='html'" v-html="scope.row[scope.column['property']]">
            </div>

            <div v-else>
              {{ scope.row[scope.column["property"]] }}
            </div>

          </template>
        </el-table-column>
        <el-table-column :fixed="fixedRight==true?'right':null" label="操作" width="150">
          <template slot-scope="scope">
            <div class="caoz">
              <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
              >编辑
              </el-button
              >

              <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
              >
                删除
              </el-button
              >

              <el-button
                  type="text"
                  size="small"
                  @click="onExport(scope.row)"
              >
                导出二维码
              </el-button
              >

            </div>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <!--分页区-->
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>


    <!--编辑窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem[keyID] ? '新增' : '编辑'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <div style="max-height: 60vh;overflow-y: auto;">
        <el-form :inline="false" label-width="150px">
          <el-row>
            <el-col
                class="myElCol"
                :span="11"
                v-for="(item, index) in propList"
                :key="index"
            >
              <el-form-item :label="item.label">
                <el-select
                    v-if="item.type === 'array'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2"
                      :value="index2"
                  >
                  </el-option>
                </el-select>

                <el-select
                    v-else-if="item.type === 'arrayObj'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2.title"
                      :value="item2.val"
                  >
                  </el-option>
                </el-select>


                <el-select
                    v-else-if="item.type === 'list'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data"
                      :key="index2"
                      :label="item2"
                      :value="item2"
                  >
                  </el-option>
                </el-select>

                <el-select
                    v-else-if="item.type === 'list_fun'"
                    v-model="editItem[item.prop]"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="(item2, index2) in item.data()"
                      :key="index2"
                      :label="item2"
                      :value="item2"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                    v-else-if="item.type === 'time'"
                    v-model="editItem[item.prop]"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间"
                >
                </el-date-picker>
                <el-input
                    v-else
                    :type="item.type === 'textarea'
                    || item.type == 'brtext'
                  || item.type === 'showHtml'
                   || item.type === 'pic'
                    || item.type === 'video'
                  ? 'textarea' : 'text'"
                    :disabled="item.prop == keyID"
                    :rows="2"
                    clearable
                    v-model="editItem[item.prop]"
                >
                </el-input>
                <el-button style="margin-top: 5px;" v-if="item.type === 'showHtml'" type="primary"
                           @click="$copy(editItem[item.prop])">复制代码
                </el-button>

                <MyUploadPlus
                    v-if="
                  item.upload === true"
                    accept="*"
                    fileType="file"
                    :disabled="false"
                    @urlChange="(e) => onUrlChange(item.prop, e,item.json==true)"
                />

              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>


<script>


import {mapState} from "vuex";

export default {
  data() {
    return {
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,

      //----------------------以下为每次的配置--------------

      /**
       `id` int(11) NOT NULL AUTO_INCREMENT,
       `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP,
       `top_id` int(11) NOT NULL DEFAULT '0' COMMENT '排序顺序(从大到小)',
       `goods_title` varchar(255) NOT NULL DEFAULT '' COMMENT '商品标题',
       `goods_pic` varchar(255) NOT NULL DEFAULT '' COMMENT '商品图片',
       `goods_info` longtext COMMENT '商品信息JSON',
       `goods_price` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '商品单价',
       `compute_info` longtext COMMENT '报价详情',
       `bz` varchar(255) NOT NULL DEFAULT '',
       */

      //表格字段
      propList: [
        {
          prop: "id",
          label: "ID",
          width: 80,
        },
        {
          prop: "goods_number",
          label: "商品编码",
          width: 160,
        },
        {
          prop: "curr_index",
          label: "当前码位置",
          width: 180,
        },
        {
          prop: "product_job",
          label: "工序列表(自动)",
          width: 160,
          type: 'brtext'
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 180,
          type: 'time'
        },

        {
          prop: "top_id",
          label: "置顶索引",
          width: 100,
        },
        {
          prop: "goods_cate",
          label: "分类名称",
          width: 120,
          type: 'list_fun',
          data: () => this.cateList,
        },
        {
          prop: "goods_title",
          label: "商品标题",
          width: 180,

        },
        {
          prop: "goods_store",
          label: "商品库存",
          width: 150,
        },
        {
          prop: "goods_pic",
          label: "商品图片",
          width: 120,
          type: 'pic',
          upload: true,
        },
        {
          prop: "goods_desc",
          label: "详情图片",
          width: 120,
          type: 'pic',
          upload: true,
        },
        {
          prop: "custom_id",
          label: "关联定制表ID",
          width: 120,
        },
        // {
        //   prop: "size_pic_json",
        //   label: "尺寸图",
        //   width: 250,
        //   type:'pic'
        // },
        // {
        //   prop: "goods_info_arr",
        //   label: "商品信息",
        //   width: 120,
        //   type:'textarea'
        // },
        {
          prop: "goods_price",
          label: "商品单价",
          width: 120,
        },
        {
          prop: "cost_price",
          label: "成本价格",
          width: 120,
        },
        // {
        //   prop: "compute_info",
        //   label: "报价详情",
        //   width: 150,
        //   type:'textarea'
        // },
        {
          prop: "is_hide",
          label: "是否隐藏",
          width: 120,
          type: 'array',
          data: ['否', '是']
        },
        {
          prop: "bz",
          label: "备注",
          width: 120,
        },
      ],
      //搜索字段
      searchFieds: {
        search: '',
        is_hide: '',
        goods_number: '',
        goods_cate: '',
      },
      //base64编码字段列表
      base64Fields: [],
      //主键ID
      keyID: 'id',
      //调整表格高度
      subHeight: 300,
      //类方法URL--获取列表
      methods_getList: 'GoodsList/getList',
      //类方法URL--保存数据
      methods_saveData: 'GoodsList/saveData',
      //类方法URL--删除一行数据
      methods_delItem: 'GoodsList/delItem',
      //操作列是否固定右边
      fixedRight: true,
      //显示选择框
      showSelection: false,

      // ------------------其它临时数据
      //分类名称列表
      cateList: [],

    };
  },
  computed: {
    ...mapState([]),
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getCateList()
    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - this.subHeight;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //获取分类列表
    async getCateList() {
      const res = await this.$post('GoodsList/getCateList');
      console.log(res);
      if (res.code !== 200) return;

      this.cateList = res.data['cate_list'];

    },
    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
      };
      //添加搜索词
      for (let key in this.searchFieds) {
        this.$set(obj, key, this.searchFieds[key])
      }

      const res = await this.$post(this.methods_getList, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
      //编辑商品域名
      this.edit_baojia_url = res.data['edit_baojia_url']
      console.log('this.tableData', this.tableData)
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.page = 1
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));

      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      //添加时主键为0
      this.$set(obj, this.keyID, 0);

      this.editItem = obj;

      this.showEditDlg = true;
    },
    //点击确定保存信息
    async onSaveEdit() {
      let obj = {
        data: JSON.parse(JSON.stringify(this.editItem)),
      };
      this.$set(obj, this.keyID, this.editItem[this.keyID])

      //处理需编码的字段
      for (let i in this.base64Fields) {
        let field = this.base64Fields[i]
        obj.data[field] = this.base64EnCode(obj.data[field])
      }

      const res = await this.$post(this.methods_saveData, obj);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },

    onUrlChange(fielddName, e, json = false) {
      console.log("获取 getUrlChange", fielddName, e);
      if (json === true) {
        let arr = this.editItem[fielddName] || []
        arr.push(e[0].url);
      } else {
        this.editItem[fielddName] = e[0].url;
      }
    },
    //选择框改变
    handleSelectionChange(val) {
      console.log(val);
      this.selectRow = val;
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该信息
      const confirmResult = await this.$confirm(
          "此操作将永久删除该信息?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let obj = {};
      this.$set(obj, this.keyID, item[this.keyID])

      console.log(obj);
      const res = await this.$post(this.methods_delItem, obj);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //按评论ID删除
    onDelByComID() {
      this.$prompt("请输入评论ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "职位ID格式不正确",
      })
          .then(({value}) => {
            if (parseInt(value) > 0) {
              this.callDelByComID(parseInt(value));
            } else {
              this.$err("评论ID有误");
            }
          })
          .catch(() => {
            this.$info("取消输入");
          });
    },
    async callDelByComID(comid = 0) {
      console.log(comid);
      const res = await this.$post(`ZhaoJobCommentFavorOppose/delCommentFavorOppose`, {
        comid,
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
    //按用户ID删除
    onDelByUserID() {
      this.$prompt("请输入用户ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "用户ID格式不正确",
      })
          .then(({value}) => {
            if (parseInt(value) > 0) {
              this.callDelByUserID(parseInt(value));
            } else {
              this.$err("用户ID有误");
            }
          })
          .catch(() => {
            this.$info("取消输入");
          });
    },
    async callDelByUserID(app_userid = 0) {
      console.log(app_userid);

      const res = await this.$post(`ZhaoJobCommentFavorOppose/delCommentFavorOpposeByUserID`, {
        app_userid,
        comid: this.searchFieds.comid
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.searchFieds.comid = ''
      this.getList(this.page);
    },
    //重置搜索
    onReset() {
      for (let i in this.searchFieds) {
        this.searchFieds[i] = ''
      }
      this.getList();
    },
    onEditGoodInfo(item) {
      console.log(item)
      if (item.custom_id < 1) {
        return this.$err('请关联一个定制表的ID')
      }

      let url = this.edit_baojia_url + '?ebid=' + item.custom_id
      window.open(url, '_blank')
    },
    //创建一个定制行并关联
    async createCustomId(gid = 0) {
      console.log(gid);

      const res = await this.$post(`GoodsList/createCustomId`, {
        gid,
      });
      console.log("创建返回", res);
      if (res.code !== 200) return;
      //填充ID
      let custom_id = res.data['custom_id']
      let index = this.tableData.findIndex((item) => {
        return item.id === gid
      })
      this.tableData[index]['custom_id'] = custom_id;
      this.$ok(res.msg);
    },
    //生成工序
    async onCreateJob(gid = 0) {
      console.log(gid);

      const res = await this.$post(`GoodsList/createJob`, {
        gid,
      });
      console.log("生成返回", res);
      if (res.code !== 200) return;
      //填充工序行
      let product_job = res.data['product_job']
      let index = this.tableData.findIndex((item) => {
        return item.id === gid
      })
      this.tableData[index]['product_job'] = product_job;
      this.$ok(res.msg);
    },
    //生成商品编码
    async onCreateCode(gid = 0) {
      console.log(gid);

      const res = await this.$post(`GoodsList/createGoodsCode`, {
        gid,
      });
      console.log("生成返回", res);
      if (res.code !== 200) return;
      //填充
      let goods_number = res.data['goods_number']
      let index = this.tableData.findIndex((item) => {
        return item.id === gid
      })
      this.tableData[index]['goods_number'] = goods_number;
      this.$ok(res.msg);
    },
    //调用生成产品列表
    async callCreateProduct(gid = 0, num = 0) {
      const res = await this.$post(`GoodsList/createProductList`, {
        gid,
        num
      });
      console.log("生成返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      this.getList()
    },
    //生成产品列表
    onCreateProduct(gid = 0) {
      console.log(gid);
      this.$prompt("请输入生成数量", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "生成数量不正确",
      })
          .then(({value}) => {
            if (parseInt(value) > 0) {
              this.callCreateProduct(gid, parseInt(value));
            } else {
              this.$err("数量有误");
            }
          })
          .catch(() => {
            this.$info("取消输入");
          });


    },
    //导出二维码
    async exportGoodsCode(idStr, table_id) {
      console.log(idStr)
      const res = await this.$post(`EmpytCode/exportGoodsCode`, {
        idStr,
        table_id
      });
      console.log("导出二维码", res);
      if (res.code !== 200) return;

      let url = this.$getUrl(res.data['file'])

      window.open(url, '_blank')
    },
    onExport(item) {
      this.$prompt("请输入起止ID,以-分隔", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d+-\d+$/,
        inputErrorMessage: "起止格式不正确",
      })
          .then(({value}) => {

            this.exportGoodsCode(value, item.id);

          })
          .catch(() => {
            this.$info("取消输入");
          });
    },


  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.show-btn {
  display: flex;
  justify-content: center;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.checkItem {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 100px;
  }
}


</style>
